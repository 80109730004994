const testimonials = [
  "I picked up you and your husband's book The Masterpiece Mindset and couldn't put it down!  I have read so many parenting books and self-help books over the years, and nothing has helped me get real-life specific and doable ways to improve my parenting/family life in a way that your book has helped me. I loved it so much. - J.W.",

  'Thank you so very much for all your advice! You saved my relationship with my daughter! You taught me how to get less stress and how to handle stress when I get it. I will always remember your advice! You are an awesome therapist! – I.Y.',

  "I'm very satisfied with the treatment I received. They dealt with me very patiently and always made me feel hopeful for my future regarding my fears and anxieties. I left with tools to lead an emotionally happy, healthy rest of my life. – H.S.",

  "A short review isn't sufficient to describe the blessing that treatment has been for me. I live a much richer and abundant life because of my year spent learning skills. I still experience anxiety, of course, but it doesn't stop me in any way. I have success academically, relationship wise, and countless other places because I chose to enroll in sessions at Mindset Family Therapy. – K.A.",

  'I will always be grateful for the help Mrs. Hagen gave to me. For a long time I felt that asking for help was a weakness, but Mrs. Hagen’s attitude made me feel like I was simply talking about my troubles with a trusted friend.  She has helped guide me out of the mental hole I was stuck in and back onto the road of life. – R.A',

  "I'm very grateful that we found Annabella. We had tried other counselors, but results were limited. Annabella’s specialty in OCD and anxiety made all the difference for my child. In addition to being knowledgeable and skilled at her craft, she is very kind, caring, and non-judgmental. She was a great fit for us. – R.S.",

  "THANK YOU SO MUCH for the help. It would have been very traumatizing for me if I hadn't had therapy before my trip, and not have what you taught me to deal with anxiety in those moments. So THANKS AGAIN! — Z.A.",

  "I want to thank you. Sincerely thank you. I'm not sure what really did it. Maybe it was the ring. Maybe it was just growing up a little. Who knows? I do think that talking to you did help me out quite a bit though. It made me think and analyze what drives my own anxiety and I have found that in general I feel less anxious overall even in the face of things that would have sent me into an eyelash and brow pulling panic attack. Thank you for being my therapist and helping me to better understand myself and how to deal with life in general. – B.J.",

  'Annabella has been a godsend to our family. Our daughter suffered from extreme anxiety that was debilitating to our whole family. She went for weeks, sometimes months, where she was unable to leave our home or even her bedroom for fear that something bad might happen to her. She would often lie in her bed for most of the day and night, curled up in a ball and cry for long periods of time. She also developed separation anxiety during this time and would become extremely upset if I (her mom) was out of her sight for even short periods of time. She was really agitated if left alone and also developed a social phobia where people were even unable to come into our home without her becoming very upset. <br><br>Through Annabella’s treatment program, our daughter, after about 12 weeks, has been able to fully function in normal ways. She now has a job, goes shopping by herself and has very little fear of places previously quite frightening to her (crowded malls, dark movie theaters, etc). By taking small steps and practicing the techniques over and over again many times, our daughter has learned the tools that she now uses when confronting a formerly frightening situation. When she feels a panic attack or anxious moment come on, she now knows that she can do self-talk and utilize the tools that she has been given to work through the moment.<br><br> We are so grateful for all of the help Annabella has given to our daughter and to us. Our daughter’s life has been drastically changed for the better where she now has hope for a promising future. We truly see this as a miracle. – P. S.',

  "I started seeing Annabella only when I was at the brink of making a decision that would affect my life negatively and permanently – just to satisfy an OCD compulsion. I only wish I had seen her years earlier. For almost a decade, my OCD had been the part of myself that I hated but I couldn't seem to get rid of. My approach to “feeling better” had been all wrong, and Annabella helped me see that and come to a lasting solution.<br><br> In my first session, her expertise was immediately apparent. Her approach was scientific but understanding. She helped me understand my issues, and was validating of what I was feeling without promoting the thoughts and behaviors that were damaging. It was hard, at first, to stop “feeding” my OCD – but with Annabella's help, I was able to start recognizing it, and “retraining my brain” to act in a healthy way. Thanks to Annabella, I've felt a relief that for years I didn't believe I could have. I would not hesitate for a second in recommending Annabella in the strongest way to my own friends and family. – C.T.",

  "I recently started seeing Jon Case for therapy for my daughter who is dealing with anxiety/ADHD and his help has been invaluable to me and my family. My family and I have done therapy on and off for different reasons, and of all the therapists I've seen, I don't know that I have gotten more out of a session consistently than I have with Jon. - J.W.",
];

export default testimonials;
