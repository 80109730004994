import React from 'react';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Testimonials from '../components/Testimonials';

export default () => (
  <React.Fragment>
    <SEO
      title="Testimonials | Mindset Family Therapy"
      description="Testimonials from current and previous Mindset Family Therapy clients. Learn why Mindset Family Therapy is the right place for your or your child's behavioral health treatment Family Therapy provide knowledgeable, empathetic, and personalized care for you or your loved one."
      pathname="/testimonials"
    />
    <Layout>
      <Testimonials />
    </Layout>
  </React.Fragment>
);
