import _ from 'lodash';
import React from 'react';
import PageHeader from '../PageHeader';
import Testimonial from './Testimonial';
import testimonials from '../../data/testimonials';

const Testimonials = () => {
  const background =
    'https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497108114/headerBackgroundTestimonials_rnois4.jpg';

  const testimonialsList = _.map(testimonials, (testimonial, index) => {
    const backgroundColor = index % 2 === 0 ? '#F4F8F9' : '#F2EEE8';
    const quoteColor = index % 2 === 0 ? '#C5D4DC' : '#CAC2B8';

    let imageNumber = index + 1;
    if (imageNumber === 11) {
      imageNumber = 1;
    }
    if (imageNumber === 12) {
      imageNumber = 2;
    }
    if (imageNumber === 13) {
      imageNumber = 3;
    }

    const gapImage = `https://res.cloudinary.com/dewd4pral/image/upload/q_60/v1497106475/gapImage${imageNumber}.jpg`;

    return (
      <Testimonial
        key={index}
        gapImage={gapImage}
        testimonialCopy={testimonial}
        backgroundColor={backgroundColor}
        quoteColor={quoteColor}
      />
    );
  });

  return (
    <main>
      <PageHeader pageCategory="About" pageName="Testimonials" headerImage={background} />
      {testimonialsList}
    </main>
  );
};

export default Testimonials;
