import React from 'react';
import PropTypes from 'prop-types';

import styles from './testimonial.module.scss';

const Testimonial = ({ gapImage, backgroundColor, testimonialCopy, quoteColor }) => {
  const imageGapStyle = {
    background: `url(${gapImage}) no-repeat center center`,
    backgroundSize: 'cover',
    width: '100%',
    height: 280,
    position: 'relative',
  };

  return (
    <section className={styles.container} style={{ background: `${backgroundColor}` }}>
      <div className={styles.contentContainer}>
        <div className={styles.testimonialContent}>
          <p dangerouslySetInnerHTML={{ __html: testimonialCopy }} />
        </div>

        <div className={styles.quoteLeft} style={{ color: `${quoteColor}` }}>
          &ldquo;
        </div>

        <div className={styles.quoteRight} style={{ color: `${quoteColor}` }}>
          &rdquo;
        </div>
      </div>

      <div style={imageGapStyle}>
        <div
          className={styles.dividerTriangle}
          style={{ borderTop: `30px solid ${backgroundColor}` }}
        />
      </div>
    </section>
  );
};

Testimonial.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  gapImage: PropTypes.string.isRequired,
  testimonialCopy: PropTypes.string.isRequired,
  quoteColor: PropTypes.string.isRequired,
};

export default Testimonial;
